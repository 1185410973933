<script setup>
import {defineOptions} from "vue";

const emit = defineEmits(['update:model-value']);

defineOptions({
    inheritAttrs: false,
});

defineProps({
    modelValue: File,
});

function onFileChange(event) {
    emit('update:modelValue', event.target.files[0])
}
</script>

<template>
    <label class="flex items-center">
        <input class="sr-only" type="file" v-bind="$attrs" @change="onFileChange" ref="input" />
        <div class="btn btn--black btn--S whitespace-nowrap">Choisir un fichier</div>
        <div class="ml-4 truncate" v-text="modelValue?.name" :title="modelValue?.name" />
    </label>
</template>

<style scoped>
label:focus-within .btn {
    @apply ring ring-secondary-dark ring-offset-2;
}
</style>
