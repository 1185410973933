import "./assets/styles/main.scss";

import { createApp } from 'vue';
import CsrfInput from "@/app/components/CsrfInput";
import HeaderCustom from "@/app/components/HeaderCustom";
import Accordion from "@/app/components/Accordion";
import CollapsePanel from "@/app/components/CollapsePanel";
import Media from "@/app/components/Media";
import GpsTracks from "@/app/components/GpsTracks.vue";
import SubscriptionForm from "@/app/components/SubscriptionForm.vue";
import LightSwitch from "@/app/components/LightSwitch.vue";
import FlashMessage from "@/app/components/FlashMessage.vue";
import MissingAttachmentsPage from "@/app/components/MissingAttachmentsPage.vue";

createApp({
    components: {
        CsrfInput,
        HeaderCustom,
        Accordion,
        CollapsePanel,
        Media,
        GpsTracks,
        SubscriptionForm,
        LightSwitch,
        FlashMessage,
        MissingAttachmentsPage,
    },
}).mount('#app');
