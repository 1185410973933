<template>
    <header class="header py-6">
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col w-full">
                    <div class="header__content flex items-center justify-between">
                        <div class="header__logo">
                            <img :src="logo.url" :alt="logo.alt" class="max-h-full" />
                        </div>
                        <div class="header__menu-content flex items-center">

                        </div>
                        <div class="header__links flex items-center ml-8">
                            <a href="#">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 1a9 9 0 0 1 7.032 14.617L23.414 22 22 23.414l-6.383-6.382A9 9 0 1 1 10 1zm0 2a7 7 0 1 0 0 14 7 7 0 0 0 0-14z" fill="#FFF" fill-rule="evenodd"/>
                                </svg>
                            </a>
                            <a href="#" class="ml-6">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.738 0a5.014 5.014 0 0 1 4.991 4.783l.005.217v.999L20.617 6l1.829 14.628A3 3 0 0 1 19.468 24H4a3 3 0 0 1-2.977-3.372L2.852 6l3.882-.001V4.997A5.014 5.014 0 0 1 11.738 0zm7.113 7.999-2.117-.001V11h-2V7.998h-6V11h-2V7.998l-2.117.001-1.61 12.877a1 1 0 0 0 .168.69l.075.096A1 1 0 0 0 4 22h15.468a1 1 0 0 0 .993-1.124l-1.61-12.877zM11.738 2A3.013 3.013 0 0 0 8.74 4.824L8.734 5v.999h6v-.996A3.012 3.012 0 0 0 11.738 2z" fill="#FFF" fill-rule="evenodd"/>
                                </svg>
                            </a>
                            <div class="header__burger-menu h-6 flex flex-col justify-center cursor-pointer ml-6 lg:hidden" @click.prevent="toggleMenu">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name : 'header-custom',

        props : {
            logo : { type : Object, default : () => {}},
            primaryMenu : { type : Array, default : () => []},
            secondaryMenu : { type : Array, default : () => []},
        },

        data(){
            return {
                menuOpen : false
            }
        },

        methods : {
            toggleMenu(){

            }
        }
    }
</script>

<style lang="scss">
    .header {
        background-color : #0b0b99;

        &__logo {
            height : 14px;
        }

        &__burger-menu {
            > div {
                @apply bg-white;
                width : 22px;
                height : 2px;
                margin-bottom : 4px;

                &:last-child {
                    @apply mb-0;
                }
            }
        }
    }
</style>
