<template>
    <div class="grid missing-attachment-form-page accent-secondary-dark">
        <div class="grid-container flex py-10 md:py-20">

            <div v-if="isTeamSectionHidden && isPilotSectionHidden && isPassengerSectionHidden"
                 class="my-auto"
            >
                <h1>Inscription déjà validée 😎</h1>
                <p>Ton inscription a bien été prise en compte et tous les justificatifs sont valides. Lâche donc cet ordi et va t'entraîner !</p>
            </div>

            <form method="post"
                  accept-charset="UTF-8"
                  enctype="multipart/form-data"
                  v-else
            >
                <csrf-input />
                <input type="hidden" name="subscriptionId" :value="subscriptionId" />
                <input type="hidden" name="action" :value="action" />
                <input type="hidden" name="redirect" :value="redirect" v-if="redirect" />

                <div class="grid-container py-10 md:py-20">
                    <div class="form">
                        <h2 class="w-full text-center mb-6 md:mb-20">{{ heading }}</h2>

                        <div class="flex flex-wrap">
                            <fieldset class="form__fieldset w-full" v-if="!isTeamSectionHidden">
                                <legend class="form__fieldset-legend">Équipe</legend>

                                <div class="flex flex-wrap">
                                    <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                        <div class="form__label">
                                            Photo
                                        </div>

                                        <file-upload name="fields[subscriptionsPicture][]"
                                                     v-model="formData.picture"
                                                     accept="image/jpeg,image/png"

                                        />
                                        <p class="text-xs mt-1 text-slate-600">Maximum 1 Mo, format JPG ou PNG</p>

                                        <div v-if="errors.subscriptionsPicture"
                                             v-text="errors.subscriptionsPicture"
                                             class="form__field-error"
                                        />
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset class="form__fieldset w-full" v-if="!isPilotSectionHidden">
                                <legend class="form__fieldset-legend">Pilote</legend>

                                <div class="flex flex-wrap">
                                    <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="missingAttachments.includes('subscriptionsPilotMedicalCertificate')">
                                        <div class="form__label">
                                            Certificat médical
                                            <span aria-hidden="true" title="Ce champ est requis">*</span>
                                        </div>

                                        <file-upload name="fields[subscriptionsPilotMedicalCertificate][]"
                                                     v-model="formData.pilotMedicalCertificate"
                                        />
                                        <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                        <div v-if="errors.subscriptionsPilotMedicalCertificate"
                                             v-text="errors.subscriptionsPilotMedicalCertificate"
                                             class="form__field-error"
                                        />
                                    </div>

                                    <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="pilotHasFfvlLicense && missingAttachments.includes('subscriptionsPilotFfvlLicense')">
                                        <div class="form__label">
                                            Licence FFVL
                                            <span aria-hidden="true" title="Ce champ est requis">*</span>
                                        </div>

                                        <file-upload name="fields[subscriptionsPilotFfvlLicense][]"
                                                     v-model="formData.pilotFfvlLicense"
                                        />
                                        <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                        <div v-if="errors.subscriptionsPilotFfvlLicense"
                                             v-text="errors.subscriptionsPilotFfvlLicense"
                                             class="form__field-error"
                                        />
                                    </div>

                                    <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="isTandem &&  missingAttachments.includes('subscriptionsQbi')">
                                        <div class="form__label">
                                            <abbr title="Qualification biplace">QBI</abbr>
                                            <span aria-hidden="true" title="Ce champ est requis">*</span>
                                        </div>

                                        <file-upload name="fields[subscriptionsQbi][]"
                                                     v-model="formData.qbi"
                                        />
                                        <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                        <div v-if="errors.subscriptionsQbi"
                                             v-text="errors.subscriptionsQbi"
                                             class="form__field-error"
                                        />
                                    </div>

                                    <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="isTandem && missingAttachments.includes('subscriptionsTandemInsurance')">
                                        <div class="form__label">
                                            Assurance biplace
                                            <span aria-hidden="true" title="Ce champ est requis">*</span>
                                        </div>

                                        <file-upload name="fields[subscriptionsTandemInsurance][]"
                                                     v-model="formData.tandemInsurance"
                                        />
                                        <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                        <div v-if="errors.subscriptionsTandemInsurance"
                                             v-text="errors.subscriptionsTandemInsurance"
                                             class="form__field-error"
                                        />
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset v-if="!isPassengerSectionHidden" class="form__fieldset w-full">
                                <legend class="form__fieldset-legend">Passager</legend>

                                <div class="flex flex-wrap">
                                    <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="missingAttachments.includes('subscriptionsPassengerMedicalCertificate')">
                                        <div class="form__label">
                                            Certificat médical
                                            <span aria-hidden="true" title="Ce champ est requis">*</span>
                                        </div>

                                        <file-upload name="fields[subscriptionsPassengerMedicalCertificate][]"
                                                     v-model="formData.passengerMedicalCertificate"
                                        />
                                        <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                        <div v-if="errors.subscriptionsPassengerMedicalCertificate"
                                             v-text="errors.subscriptionsPassengerMedicalCertificate"
                                             class="form__field-error"
                                        />
                                    </div>

                                    <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="isPassengerMinor && missingAttachments.includes('subscriptionsPassengerParentalAuthorization')">
                                        <div class="form__label">
                                            Autorisation parentale
                                            <span aria-hidden="true" title="Ce champ est requis">*</span>
                                        </div>

                                        <file-upload name="fields[subscriptionsPassengerParentalAuthorization][]"
                                                     v-model="formData.passengerParentalAuthorization"
                                        />
                                        <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                        <a :href="parentalAuthorizationTemplateUrl" class="text-xs mt-2" target="_blank" download="autorisation-parentale-fly-in-fiz.pdf">
                                            Modèle d'autorisation parentale
                                        </a>

                                        <div v-if="errors.subscriptionsPassengerParentalAuthorization"
                                             v-text="errors.subscriptionsPassengerParentalAuthorization"
                                             class="form__field-error"
                                        />
                                    </div>
                                </div>
                            </fieldset>

                            <p class="grid-col w-full text-xs text-slate-600 mt-4">Les champs marqués d'un astérisque (*) devront être transmis avant le départ de la course.</p>
                            <div class="w-full flex justify-center mt-10 md:mt-20">
                                <button type="submit" class="btn btn--white bg-secondary-dark">Je m'inscris</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import CsrfInput from "@/app/components/CsrfInput.vue";
import FileUpload from "@/app/components/FileUpload.vue";

import {reactive, computed} from "vue";

const props = defineProps({
    subscriptionId: { type: String, required: true },
    action: { type: String, required: true },
    redirect: { type: String, default: null },
    heading: { type: String, default: null },
    missingAttachments: { type: Array, required: true },
    errors: { type: Object, default: () => ({}) },
    isTandem: { type: Boolean, required: true },
    pilotHasFfvlLicense: { type: Boolean, required: true },
    passengerDateOfBirth: { type: String, required: true },
    parentalAuthorizationTemplateUrl: { type: String, required: true },
});

const formData = reactive({
    picture: undefined,
    pilotMedicalCertificate: undefined,
    pilotFfvlLicense: undefined,
    qbi: undefined,
    tandemInsurance: undefined,
    passengerMedicalCertificate: undefined,
    passengerParentalAuthorization: undefined,
});

const isPassengerMinor = computed(() => {
    if (!props.isTandem || !props.passengerDateOfBirth) {
        return false;
    }

    const majorityDate = new Date(props.passengerDateOfBirth);
    majorityDate.setFullYear(majorityDate.getFullYear() + 18);

    const contestDate = new Date('2023-09-30');

    return majorityDate > contestDate;
});

const isPilotSectionHidden = computed(() => {
    if (
        props.missingAttachments.includes('subscriptionsPilotMedicalCertificate')
        || props.missingAttachments.includes('subscriptionsPilotFfvlLicense')
    ) {
        return false;
    }

    if (props.isTandem && (
        props.missingAttachments.includes('subscriptionsQbi')
        || props.missingAttachments.includes('subscriptionsTandemInsurance')
    )) {
        return false;
    }

    return true;
});

const isPassengerSectionHidden = computed(() => {
    if (!props.isTandem) {
        return true;
    }

    if (props.missingAttachments.includes('subscriptionsPassengerMedicalCertificate')) {
        return false;
    }

    if (isPassengerMinor.value && props.missingAttachments.includes('subscriptionsPassengerParentalAuthorization')) {
        return false;
    }

    return true;
});

const isTeamSectionHidden = computed(() => {
    return !props.missingAttachments.includes('subscriptionsPicture');
});
</script>

<style scoped>
.missing-attachment-form-page {
    @apply bg-secondary-light;
    background-image: url("~@/assets/topo-lines.png");
    background-size: 400px;
}
</style>
