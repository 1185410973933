<template>
    <div id="container">
        <mapbox-map :accessToken="configMapbox.accessToken"
                    :bounds="[
                        { lat: 45.974805416663656, lng: 6.797006042078209 },
                        { lat: 45.885606436172736, lng: 6.615218271703128 },
                    ]"
                    @loaded="onMapLoaded"
                    map-style="mapbox://styles/mapbox/outdoors-v9"
                    :cooperative-gestures="true"
        >
            <MapboxNavigationControl />
        </mapbox-map>
    </div>
</template>

<script>
import {MapboxMap, MapboxNavigationControl} from "vue-mapbox-ts";
import {LngLatBounds} from 'mapbox-gl';
import {ref} from "vue";
import configMapbox from "@/app/config/mapbox"
import mapMarkers from "@/app/map-markers";

function centerMap(tracks, _map) {

    // Create a 'LngLatBounds' with both corners at the first coordinate.
    const bounds = new LngLatBounds(
        [(tracks[0].geometry.coordinates)[0][0], (tracks[0].geometry.coordinates)[0][1]],
        [(tracks[0].geometry.coordinates)[0][0], (tracks[0].geometry.coordinates)[0][1]],
    );

    tracks.forEach(track => {
        const coordinates = track.geometry.coordinates;

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
            bounds.extend([coord[0], coord[1]]);
        }
    });

    _map.fitBounds(bounds, {padding: 120});
}

export default {
    name: "gps-tracks",

    props: {
        url: String,
    },

    components: {
        MapboxMap,
        MapboxNavigationControl,
    },

    data() {
        return {};
    },

    setup(props) {
        const map = ref(null);

        async function onMapLoaded(_map) {
            map.value = _map;

            _map.cooperativeGestures = true;

            const response = await fetch(props.url);
            const geoJsonData = await response.json();

            await Promise.all(Object.entries(mapMarkers).map(async ([name, url]) => {
                const marker = await new Promise((resolve, reject) => {
                    _map.loadImage(url, (err, image) => err ? reject(err) : resolve(image))
                });
                _map.addImage(name, marker);
            }));


            _map.addSource('route', {
                type: 'geojson',
                data: geoJsonData,
            });

            const tracks = geoJsonData.features.filter(feature => feature.geometry.type === 'LineString');

            centerMap(tracks, _map);

            tracks.forEach(track => {
                _map.addLayer({
                    id: `track-${track.id}`,
                    type: 'line',
                    source: 'route',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                    },
                    paint: {
                        'line-color': ['get', 'color'],
                        'line-width': 6,
                    },
                    filter: ['==', '$type', 'LineString'],
                });
            });

            _map.addLayer({
                id: 'points',
                type: 'symbol',
                source: 'route',
                layout: {
                    'icon-image': ['get', 'marker_type'],
                    'icon-size': 0.5,
                    'icon-anchor': ['get', 'marker_anchor'],
                    'text-field': ['get', 'title'],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-offset': [-1.5, 0],
                    'text-anchor': 'right',
                },
                filter: ['==', '$type', 'Point'],
            });
        }

        return {
            configMapbox,
            map,
            onMapLoaded,
        }
    }

}
</script>

<style scoped>
    #container {
        height: 80vh;
    }
</style>
