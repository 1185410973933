<template>
    <section class="collapse-panel pt-6 pb-8 lg:pt-16 lg:pb-20" :style="'background-color : '+ backgroundColor">
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col w-full">
                    <div class="collapse-panel__content flex justify-center pb-6">
                        <a
                            ref="opener"
                            href="#"
                            :class="{'btn--black' : textColor === 'dark', 'btn--white' : textColor === 'light'}"
                            class="btn flex items-center"
                            @click.prevent="togglePanel"
                        >
                            <span ref="openerText">{{ nonOpenLinkText }}</span>
                            <svg ref="arrow" class="ml-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" :fill="(textColor === 'light') ? '#000000' : '#ffffff'" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div ref="container" class="collapse-panel__panel-content bg-grey-95 h-0 overflow-hidden">
            <div ref="inner" class="collapse-panel__panel-inner pt-6 pb-8">
                <div class="grid-container">
                    <div class="grid-row">
                        <div class="grid-col">
                            <h4 v-html="heading"></h4>

                            <div class="cms-text mt-4" v-html="text"></div>

                            <a
                                v-if="cta && cta.value"
                                :href="cta.value"
                                :target="cta.target"
                                class="link link--S block mt-4"
                            >{{ cta.customText }}</a>

                            <div v-if="image && image.url" class="w-full flex justify-center mt-6">
                                <img :src="image.url"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import gsap from 'gsap';

    export default {
        name : 'collapse-panel',

        props : {
            // Text when the link is not open
            nonOpenLinkText : { type : String, default : ''},

            // Text when the link is open
            openLinkText : { type : String, default : ''},

            // Heading inside the panel
            heading : { type : String, default : ''},

            // Text inside the panel
            text : { type : String, default : ''},

            // CTA inside the panel
            cta : { type : Object, default : () => {}},

            // Image inside the panel
            image : { type : Object, default : () => {}},

            // Background color for the block
            backgroundColor : { type : String, default : 'light'},

            // Text color for the block
            textColor : { type : String, default : 'dark'}
        },

        data(){
            return {
                panelOpen : false
            }
        },

        methods : {
            // Open/close the panel
            togglePanel(){

                // Show the panel
                gsap.to(this.$refs.container, {
                    height : (this.panelOpen) ? 0 : this.$refs.inner.clientHeight,
                    duration : 0.5,
                    ease : 'power3.out'
                });

                // Save the state
                this.panelOpen = !this.panelOpen;

                // Update the text
                this.$refs.openerText.innerHTML = (this.panelOpen) ? this.openLinkText : this.nonOpenLinkText;

                // If there's an arrow
                if(this.$refs.arrow) {
                    // Flip it
                    gsap.to(this.$refs.arrow, {
                        rotation : (this.panelOpen) ? '-180deg' : '0deg',
                        duration : 0.4,
                        ease : 'power3.out'
                    })
                }
            }
        }
    }
</script>
