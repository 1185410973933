<template>
    <section class="media pt-10 pb-12 lg:pt-16 lg:pb-20">
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col w-full relative">
                    <div class="media__container relative flex justify-center">
                        <template v-if="mediaMobile.type === 'image' && mediaDesktop.type === 'image'">
                            <picture>
                                <source v-if="mediaDesktop" :srcset="mediaDesktop.url" media="(min-width: 768px)">
                                <img
                                    v-if="mediaMobile"
                                    :src="mediaMobile.url"
                                    :alt="mediaMobile.alt"
                                />
                            </picture>
                        </template>

                        <template v-if="mediaMobile.type === 'video' && mediaDesktop.type === 'video'">
                            <video
                                ref="videoMobile"
                                :poster="videoPoster.url"
                                class="media__video-mobile md:hidden"
                                :controls="(!autoplay) ? 'controls' : null"
                                :muted="(autoplay) ? 'muted' : null"
                            >
                                <source :src="mediaMobile.url" type="video/mp4" />
                            </video>
                            <video
                                ref="videoDesktop"
                                :poster="videoPoster.url"
                                class="media__video-desktop hidden md:block"
                                :controls="(!autoplay) ? 'controls' : null"
                                :muted="(autoplay) ? 'muted' : null"
                            >
                                <source :src="mediaDesktop.url" type="video/mp4" />
                            </video>

                            <div
                                v-if="!autoplay"
                                ref="videoPreview"
                                class="media__video-preview absolute inset-0 w-full cursor-pointer"
                                @click.prevent="playVideo"
                            >
                                <img v-if="videoPoster" :src="videoPoster.url" />

                                <div
                                    ref="play"
                                    class="media__play-button w-14 h-14 bg-black rounded-full flex justify-center items-center flex-shrink-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                                    @click.prevent="playVideo"
                                >
                                    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.167 4.669v18.666a1.167 1.167 0 0 0 1.853.944l12.833-9.333c.64-.466.64-1.422 0-1.887L10.02 3.725a1.167 1.167 0 0 0-1.853.944zM10.5 6.96l9.682 7.042-9.682 7.04V6.96z" fill="#FFF" fill-rule="evenodd"/>
                                    </svg>
                                </div>
                            </div>

                        </template>
                    </div>
                    <div
                        v-if="!autoplay"
                        ref="legend"
                        :class="{
                            'label' : mediaMobile.type === 'image' && mediaDesktop.type === 'image',
                            'h4 md:text-white uppercase md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:mt-8 cursor-pointer' : mediaMobile.type === 'video' && mediaDesktop.type === 'video'
                        }"
                        class="legend mt-4 text-center"
                    >{{ legend }}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import gsap from 'gsap';

    export default {
        name : 'media',

        props : {
            // Media for the mobile
            mediaMobile : { type : Object, default : () => {}},

            // Media for the desktop
            mediaDesktop : {type : Object, default : () => {}},

            // Poster for the video
            videoPoster : {type : Object, default : () => {}},

            // Legend of the media
            legend : {type : String, default : ''},

            // Option to set autoplay on video
            autoplay : {type : Boolean, default : false},

            // Background color for the block
            backgroundColor : { type : String, default : 'light'},

            // Text color for the block
            textColor : { type : String, default : 'dark'}
        },

        data(){
            return {
                // Intersection observer options
                options : {
                    rootMargin : '0px 0px -20% 0px',
                    threshold : 0.5
                }
            }
        },

        mounted(){
            // if we have autoplay on video
            if(this.autoplay) {

                // Intersection observer init
                this.observer = new IntersectionObserver(this.onIntersect, this.options);

                this.observer.observe(this.$el);
            }
        },

        methods : {

            // Launch the video
            playVideo(){

                // Hide poster
                gsap.to([this.$refs.videoPreview, this.$refs.legend], {
                    opacity : 0,
                    display : 'none',
                    duration : 0.4,
                    ease : 'power3.out'
                });

                // If we are on desktop
                if(window.innerWidth > 767) {
                    // Play desktop video
                    this.$refs.videoDesktop.play();
                } else {
                    // Play mobile video
                    this.$refs.videoMobile.play();
                }
            },

            // On intersection
            onIntersect(pEntry){
                // If we enter the block
                if(pEntry[0].isIntersecting) {
                    console.log('intersect', pEntry);

                    // If we are on desktop
                    if(window.innerWidth > 767) {
                        // Play desktop video
                        this.$refs.videoDesktop.play();
                    } else {
                        // Play mobile video
                        this.$refs.videoMobile.play();
                    }
                }
                // If we leave the block
                else {
                    // If we are on desktop
                    if(window.innerWidth > 767) {
                        // Play desktop video
                        this.$refs.videoDesktop.pause();
                    } else {
                        // Play mobile video
                        this.$refs.videoMobile.pause();
                    }
                }
            },
        }
    }
</script>
